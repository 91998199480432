@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes wobble {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-5deg); }
    75% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
}

.wine-wobble {
    animation: wobble 1s ease-in-out infinite;
}

.modal-overlay {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.modal-overlay.show {
    opacity: 1;
}

.modal-content {
    transform: scale(0.5);
    transition: transform 0.3s ease-in-out;
}

.modal-content.show {
    transform: scale(1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out forwards;
} 